<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        <b>Vernier Calipers</b>
      </p>
      <p class="mb-3">
        How helpful did you find the instructional video in understanding the lab techniques?
      </p>
      <p class="mb-2">Likert Scale (1 - Not Helpful, 5 - Extremely Helpful)</p>
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-2 ml-6">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">How clear was the explanation provided in the video?</p>
      <p class="mb-2">Likert Scale (1 - Very Unclear, 5 - Extremely Clear)</p>
      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-2 ml-6">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        Do you think the instructional video adequately prepared you for performing the lab
        technique(s) in person?
      </p>
      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="mb-2 ml-6">
        <v-radio
          v-for="option in options2"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
      <s-textarea v-model="inputs.input4" outlined />

      <p class="mb-2">Was any part of the video confusing or challenging to follow?</p>
      <s-textarea v-model="inputs.input5" outlined />

      <p class="mb-2">How would you rate the pacing of the video?</p>
      <v-radio-group v-model="inputs.input6" :disabled="!allowEditing" class="mb-3 ml-6">
        <v-radio
          v-for="option in options3"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'OleMissLab9VideoResponsesQ2',
  components: {
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      options1: [
        {
          text: '1',
          value: 'one',
        },
        {
          text: '2',
          value: 'two',
        },
        {
          text: '3',
          value: 'three',
        },
        {
          text: '4',
          value: 'four',
        },
        {
          text: '5',
          value: 'five',
        },
      ],
      options2: [
        {text: 'Yes - Please explain why this is your answer.', value: 'yes'},
        {text: 'No - Please explain why this is your answer.', value: 'no'},
      ],
      options3: [
        {text: 'Too Fast', value: 'tooFast'},
        {text: 'Just Right', value: 'justRight'},
        {text: 'Too Slow', value: 'tooSlow'},
      ],
    };
  },
};
</script>
<style scoped></style>
